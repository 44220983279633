import React from 'react';
import PropTypes from 'prop-types';

import IconCalendar2 from 'components/icons/iconCalendar2';

const ReturnsIcon = ({ color }) => (
  <IconCalendar2 width="20" height="26" color={color} />
);

ReturnsIcon.propTypes = {
  color: PropTypes.string,
};

export default ReturnsIcon;
