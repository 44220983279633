import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Placeholder from 'components/consumer/Placeholder';
import IconClose from 'components/icons/iconClose';
import IconBirdLogo from 'components/icons/iconBirdLogo';
import { colorWhite } from 'styles/layout/colors';

const PDPNotification = ({
  heading,
  subheading,
  loading = false,
  onClose,
  showIcon,
  showLogo = false,
  baseMargin = false,
  showCloseButton = true,
  brandedBG = false,
  showCTA,
  baseStyleExtension = undefined,
}) => (
  <Placeholder
    loading={loading}
    type="rect"
    className="!bg-yellow-light rounded min-h-[73px] max-[767px]:min-h-[122px]"
  >
    <div
      className={classNames(
        'text-white bg-brand-dark [background-image:url(https://joybird2.imgix.net/user-uploads-staging/joybird-teal-new.jpg?w=400&cs=srgb)] rounded flex items-start justify-between p-5 mt-4 relative',
        {
          'animate-fade-in': loading === false,
          'm-5': baseMargin,
          '!bg-cover': brandedBG,
        },
        baseStyleExtension
      )}
    >
      {!!showIcon && <div className="min-w-[48px]">{showIcon}</div>}

      {!!showLogo && (
        <div className="min-w-[48px]">
          <IconBirdLogo color="#016A78" />
        </div>
      )}

      <div className="flex-grow text-white">
        <div className="text-base font-bold">{heading}</div>
        <div className="text-xs">{subheading}</div>
        {!!showCTA && <br />}
        {!!showCTA && showCTA}
      </div>

      {showCloseButton && (
        <button
          className={classNames(
            '[background:none] border-0 p-2.5 absolute right-0 top-0 hover:cursor-pointer',
            '[&_svg]:h-3 [&_svg]:w-3',
            {
              '[&>svg>path]:!fill-white': brandedBG,
            }
          )}
          type="button"
          onClick={onClose}
        >
          <IconClose color={colorWhite} height={9} width={9} />
        </button>
      )}
    </div>
  </Placeholder>
);

PDPNotification.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  loading: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  showIcon: PropTypes.node,
  showLogo: PropTypes.bool,
  baseMargin: PropTypes.bool,
  brandedBG: PropTypes.bool,
  showCTA: PropTypes.node,
  baseStyleExtension: PropTypes.string,
};

export default PDPNotification;
