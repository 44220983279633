import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StandardModal from 'components/consumer/StandardModal';
import useScreen from 'hooks/useScreen';
import KeySellingPointsBarItem from '../KeySellingPointsBarItem';
import WarrantyIcon from '../icons/WarrantyIcon';

const WarrantyItem = ({ openMobileModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useScreen();

  return (
    <>
      <KeySellingPointsBarItem
        icon={<WarrantyIcon color="currentColor" />}
        onClick={() => {
          if (isMobileOrTablet) {
            openMobileModal({
              content: `This type of coverage applies to all of the foundational elements of your furniture: wood frames, joints, bases, legs, and hardware, which are integral to the life of your product. We promise to pay standard rates for repair if needed or to provide an alternative solution should the issue be unable to be repaired.`,
              icon: <WarrantyIcon color="white" />,
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        Limited lifetime warranty*
      </KeySellingPointsBarItem>

      <StandardModal
        isOpen={isOpen}
        icon={
          <div className="KeySellingPointsBarItem-modal-icon">
            <WarrantyIcon color="white" />
          </div>
        }
        onClose={() => {
          setIsOpen(false);
        }}
        title="Limited lifetime warranty"
      >
        This type of coverage applies to all of the foundational elements of
        your furniture: wood frames, joints, bases, legs, and hardware,
        which are integral to the life of your product. We promise to pay
        standard rates for repair if needed or to provide an alternative
        solution should the issue be unable to be repaired.
      </StandardModal>
    </>
  );
};

WarrantyItem.propTypes = {
  openMobileModal: PropTypes.func,
};

export default WarrantyItem;
