import React from 'react';
import PropTypes from 'prop-types';

import IconDeliveryFlat from 'components/icons/iconDeliveryFlat';

const DeliveryIcon = ({ color }) => (
  <IconDeliveryFlat width="23" height="26" color={color} />
);

DeliveryIcon.propTypes = {
  color: PropTypes.string,
};

export default DeliveryIcon;
