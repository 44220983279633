import React from 'react';
import PropTypes from 'prop-types';

const IconMaterialsDesigned = props => {
  const color = props.color || '#585858';
  return (
    <svg width={40} height={48} viewBox="0 0 40 48" {...props} >
      <g stroke={color} strokeWidth={2} fill="none" fillRule="evenodd">
        <path d="M32.581 40.335l-11.29 6.321a2.675 2.675 0 01-2.644-.011l-11.155-6.37a2.803 2.803 0 01-1.398-2.417L6 25.245a2.806 2.806 0 011.405-2.462l11.319-6.431a2.68 2.68 0 012.612-.024l11.219 6.116A2.798 2.798 0 0134 24.906v12.982a2.801 2.801 0 01-1.419 2.447zM20 32v15" />
        <path d="M6 24l12.71 6.678c.806.425 1.777.43 2.588.014L34 24.172M20 11V0M9 16L1 8M39 7l-8 8" />
      </g>
    </svg>
  );
};

IconMaterialsDesigned.propTypes = {
  color: PropTypes.string
}

export default IconMaterialsDesigned;
