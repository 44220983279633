import React from 'react';
import PropTypes from 'prop-types';

const IconAffirmLogo = ({ width = 23, height = 26, color = 'gray' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 180 120"
  >
    <g clipPath="url(#clip0_8975_43162)">
      <mask
        id="mask0_8975_43162"
        style={{ maskType: 'luminance' }}
        width="175"
        height="129"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M174.82 0H0v128.16h174.82V0z" />
      </mask>
      <g fill={color} mask="url(#mask0_8975_43162)">
        <path d="M28.53 125.9c-7.26-11.06-11.49-24.26-11.49-38.45 0-38.76 31.53-70.29 70.29-70.29 38.76 0 70.29 31.53 70.29 70.29 0 14.18-4.24 27.39-11.49 38.45h19.68a86.89 86.89 0 008.93-38.45c0-48.2-39.21-87.4-87.4-87.4-48.19 0-87.4 39.21-87.4 87.4a86.89 86.89 0 008.93 38.45h19.66z" />
        <path d="M88.51 45.92c-12.8 0-27.53 6.03-35.53 12.41l7.3 15.37c6.41-5.87 16.78-10.88 26.13-10.88 8.89 0 13.79 2.97 13.79 8.96 0 4.03-3.26 6.3-9.4 6.86-23.06 2.12-40.98 9.32-40.98 27.02 0 14.04 10.12 22.52 26.77 22.52 11.14 0 19.89-6.19 24.61-14.36v12.07h20.76V75.3c.01-20.89-14.52-29.38-33.45-29.38zm-6.06 66.04c-6.73 0-10.42-2.88-10.42-7.61 0-9.86 12.05-12.24 27.74-12.24 0 10.32-6.91 19.85-17.32 19.85z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8975_43162">
        <path fill="#fff" d="M0 0H175V129H0z" />
      </clipPath>
    </defs>
  </svg>
);

IconAffirmLogo.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IconAffirmLogo;
