import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StandardModal from 'components/consumer/StandardModal';
import CtaLink from 'components/essentials/Cta/CtaLink';
import useScreen from 'hooks/useScreen';
import DesignServicesIcon from '../icons/DesignServicesIcon';
import KeySellingPointsBarItem from '../KeySellingPointsBarItem';

const DesignServicesItem = ({ openMobileModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useScreen();

  return (
    <>
      <KeySellingPointsBarItem
        icon={<DesignServicesIcon color="currentColor" />}
        onClick={() => {
          if (isMobileOrTablet) {
            openMobileModal({
              content: `Bring your dream space to life with help from our 3D room planner, expert tips and tricks, 
              and inspiring room designs - for free!`,
              icon: <DesignServicesIcon color="white" />,
              cta: (
                <CtaLink
                  to="/free-design-services/"
                  styling="solid-button"
                  color="brand"
                >
                  Get started
                </CtaLink>
              ),
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        Free Design Services*
      </KeySellingPointsBarItem>

      <StandardModal
        isOpen={isOpen}
        icon={
          <div className="KeySellingPointsBarItem-modal-icon">
            <DesignServicesIcon color="white" />
          </div>
        }
        onClose={() => {
          setIsOpen(false);
        }}
        title="Free Design Services"
      >
        Bring your dream space to life with help from our 3D room planner,
        expert tips and tricks, and inspiring room designs - for free!
        <div className="mt-6">
          <CtaLink
            to="/free-design-services/"
            styling="solid-button"
            color="brand"
          >
            Get started
          </CtaLink>
        </div>
      </StandardModal>
    </>
  );
};

DesignServicesItem.propTypes = {
  openMobileModal: PropTypes.func,
};

export default DesignServicesItem;
