import React from 'react';
import PropTypes from 'prop-types';

import IconGuarantee from 'components/icons/iconGuarantee';

const WarrantyIcon = ({ color }) => (
  <IconGuarantee width="20" height="26" color={color} />
);

WarrantyIcon.propTypes = {
  color: PropTypes.string,
};

export default WarrantyIcon;
