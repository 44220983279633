import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StandardModal from 'components/consumer/StandardModal';
import useScreen from 'hooks/useScreen';
import KeySellingPointsBarItem from '../KeySellingPointsBarItem';
import DeliveryIcon from '../icons/DeliveryIcon';

const DeliveryItem = ({ openMobileModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useScreen();

  return (
    <>
      <KeySellingPointsBarItem
        icon={<DeliveryIcon color="currentColor" />}
        onClick={() => {
          if (isMobileOrTablet) {
            openMobileModal({
              content: `Pay only 1 flat rate shipping fee, dependent on your zip code. Opt for
              contactless delivery, and our carriers will deliver your order to your
              front step or nearest building entrance.`,
              icon: <DeliveryIcon color="white" />,
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        Contactless, flat-rate delivery*
      </KeySellingPointsBarItem>

      <StandardModal
        isOpen={isOpen}
        icon={
          <div className="KeySellingPointsBarItem-modal-icon">
            <DeliveryIcon color="white" />
          </div>
        }
        onClose={() => {
          setIsOpen(false);
        }}
        title="Contactless, flat-rate delivery"
      >
        Pay only 1 flat rate shipping fee, dependent on your zip code. Opt for
        contactless delivery, and our carriers will deliver your order to your
        front step or nearest building entrance.
      </StandardModal>
    </>
  );
};

DeliveryItem.propTypes = {
  openMobileModal: PropTypes.func,
};

export default DeliveryItem;
