import React from 'react';
import PropTypes from 'prop-types';
import { colorGray } from 'styles/layout/colors';

const IconDesignServices = ({
  color = colorGray,
  width = '20',
  height = '21',
  className = '',
}) => (
  <svg width={width} height={height} viewBox="0 0 20 21" className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M5.79 17.565L16.625 6.732 14.21 4.318 3.377 15.152l2.414 2.413zm3.684-2.195L6.163 18.68l-.177.118-5.262 2.104c-.43.172-.857-.254-.685-.683l2.106-5.264.116-.176 3.311-3.312L.156 6.051c-.206-.205-.206-.539 0-.744l4.21-4.21c.205-.206.539-.206.744 0l5.417 5.416 5.108-5.108c.617-.617 1.617-.617 2.233 0l1.669 1.669c.617.616.617 1.616 0 2.233l-5.109 5.108 5.417 5.417c.206.206.206.54 0 .744l-4.21 4.211c-.205.205-.539.205-.744 0L9.474 15.37zm.745-.744l5.044 5.044 3.466-3.465-1.361-1.361-.68.68c-.496.496-1.24-.248-.744-.744l.68-.68-1.36-1.361-1.733 1.733c-.497.496-1.24-.249-.744-.744l1.732-1.734-.835-.834-3.465 3.466zm-3.903-3.902l3.467-3.466-.835-.834-.68.68c-.496.496-1.241-.248-.745-.744l.681-.681-1.36-1.361L5.11 6.05c-.496.496-1.24-.248-.744-.744l1.732-1.733-1.36-1.36-3.466 3.465 5.044 5.045zm-1.462 7.394l-2.029-2.03-1.352 3.382 3.381-1.352zM14.955 3.574l2.413 2.414 1.425-1.425c.206-.206.206-.539 0-.744l-1.67-1.67c-.205-.205-.538-.205-.744 0l-1.424 1.425z"
    />
  </svg>
);

IconDesignServices.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default IconDesignServices;
