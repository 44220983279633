import React from 'react';

const iconGuarantee = ({
  className = '',
  color = '#27919C',
  height = '34',
  width = '34',
}) => (
    <svg
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-891.000000, -2918.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g transform="translate(890.000000, 2917.000000)">
            <path d="M6.59773172,6.59752803 C12.8960652,0.300807016 23.1062973,0.300807016 29.4031201,6.59762987 L29.4031201,6.59762987 L27.8121299,8.18862013 C22.3939527,2.77044298 13.6081848,2.77044298 8.18862013,8.18862013 C2.77045996,13.6067803 2.77045996,22.3924697 8.18851828,27.810528 C13.6081848,33.228807 22.3939527,33.228807 27.8119669,27.8107929 C31.1113166,24.510091 32.4915047,19.846631 31.6220657,15.3481054 L31.6220657,15.3481054 L33.8311843,14.9211446 C34.8408933,20.1454354 33.236409,25.5667603 29.4031201,29.4016201 C23.1062973,35.698443 12.8960652,35.698443 6.59762987,29.4016201 C0.300790043,23.1047803 0.300790043,12.8944697 6.59773172,6.59752803 Z M33.2530774,5.91994857 L34.8368726,7.51810143 L17.6393726,24.5611014 C17.1973151,24.9991876 16.483561,24.9951211 16.0465238,24.5520264 L16.0465238,24.5520264 L9.44502381,17.8590264 L11.0469262,16.2790236 L16.8565501,22.1691717 L33.2530774,5.91994857 Z" />
          </g>
        </g>
      </g>
    </svg>
  );

export default iconGuarantee;
