import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CtaLink from 'components/essentials/Cta/CtaLink';
import StandardModal from 'components/consumer/StandardModal';
import useScreen from 'hooks/useScreen';
import KeySellingPointsBarItem from '../KeySellingPointsBarItem';
import ReturnsIcon from '../icons/ReturnsIcon';

const ReturnsItem = ({ openMobileModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useScreen();

  return (
    <>
      <KeySellingPointsBarItem
        icon={<ReturnsIcon color="currentColor" />}
        onClick={() => {
          if (isMobileOrTablet) {
            openMobileModal({
              content: ``,
              cta: (
                <>
                  Decided your furniture isn’t exactly what you wanted? Send it
                  back within 90 days for a refund.
                  <div className="m-0 mt-6">
                    <CtaLink
                      to="/returns/"
                      styling="solid-button"
                      color="brand"
                    >
                      Learn More
                    </CtaLink>
                  </div>
                </>
              ),
              icon: <ReturnsIcon color="white" />,
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        90-day returns*
      </KeySellingPointsBarItem>

      <StandardModal
        isOpen={isOpen}
        icon={
          <div className="KeySellingPointsBarItem-modal-icon">
            <ReturnsIcon color="white" />
          </div>
        }
        onClose={() => {
          setIsOpen(false);
        }}
        title="90-day returns"
      >
        Decided your furniture isn’t exactly what you wanted? Send it back
        within 90 days for a refund.
        <div className="m-0 mt-6">
          <CtaLink
            to="/returns-and-exchanges/"
            styling="solid-button"
            color="brand"
          >
            Learn More
          </CtaLink>
        </div>
      </StandardModal>
    </>
  );
};

ReturnsItem.propTypes = {
  openMobileModal: PropTypes.func,
};

export default ReturnsItem;
