import React, { useState } from 'react';

import PDPNotification from 'components/consumer/PDP/PDPNotification';
import useScreen from 'hooks/useScreen';
import DeliveryItem from './items/DeliveryItem';
import BuyNowPayLaterItem from './items/BuyNowPayLaterItem';
import ReturnsItem from './items/ReturnsItem';
import WarrantyItem from './items/WarrantyItem';
import DesignServicesItem from './items/DesignServicesItem';

const KeySellingPointsBar = () => {
  const [mobileModalOpen, setMobileModalOpen] = useState(false);
  const [mobileModalContent, setmobileModalContent] = useState('');
  const [mobileModalIcon, setmobileModalIcon] = useState({
    icon: null,
    cta: null,
  });

  const { isMobileOrTablet } = useScreen();

  const openModal = ({ content = '', icon = null, cta = null }) => {
    setmobileModalContent(content);
    setmobileModalIcon({ icon, cta });
    setMobileModalOpen(true);
  };

  const closeModal = () => {
    setMobileModalOpen(false);
  };

  return (
    <>
      {mobileModalOpen && isMobileOrTablet && (
        <PDPNotification
          baseMargin
          brandedBG
          heading={mobileModalContent}
          onClose={closeModal}
          showIcon={mobileModalIcon.icon}
          showCTA={mobileModalIcon.cta}
        />
      )}

      <div className="text-base bg-gray-light8 text-gray flex h-[62px] justify-center p-0 w-full md:h-20">
        <div className="bg-gray-light8 flex flex-row max-w-[100vw] overflow-x-auto w-auto lg:px-[5px] lg:py-0">
          <DeliveryItem openMobileModal={openModal} />
          <BuyNowPayLaterItem openMobileModal={openModal} />
          <ReturnsItem openMobileModal={openModal} />
          <WarrantyItem openMobileModal={openModal} />
          <DesignServicesItem openMobileModal={openModal} />
        </div>
      </div>
    </>
  );
};

export default KeySellingPointsBar;
