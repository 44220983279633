import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CtaLink from 'components/essentials/Cta/CtaLink';
import StandardModal from 'components/consumer/StandardModal';
import useScreen from 'hooks/useScreen';
import IconAffirmLogo from 'components/icons/iconAffirmLogo';
import KeySellingPointsBarItem from '../KeySellingPointsBarItem';

const BuyNowPayLaterItem = ({ openMobileModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobileOrTablet } = useScreen();

  return (
    <>
      <KeySellingPointsBarItem
        icon={<IconAffirmLogo color="currentColor" />}
        onClick={() => {
          if (isMobileOrTablet) {
            openMobileModal({
              content: ``,
              icon: <IconAffirmLogo color="white" />,
              cta: (
                <>
                  Unlock your exclusive financing offer in seconds with affirm.
                  Rates from 0-36% APR. Payment options through Affirm are
                  subject to an eligibility check and are provided by these
                  lending partners:{' '}
                  <a
                    href="https://www.affirm.com/lenders"
                    target="_blank"
                    className="text-white underline"
                    rel="noreferrer"
                  >
                    affirm.com/lenders
                  </a>
                  <div className="mt-6">
                    <CtaLink
                      to="/product-financing/"
                      styling="solid-button"
                      color="brand"
                    >
                      Learn More
                    </CtaLink>
                  </div>
                </>
              ),
            });
          } else {
            setIsOpen(true);
          }
        }}
      >
        As low as 0% APR with Affirm
      </KeySellingPointsBarItem>

      <StandardModal
        isOpen={isOpen}
        icon={
          <div className="KeySellingPointsBarItem-modal-icon">
            <IconAffirmLogo color="white" />
          </div>
        }
        onClose={() => {
          setIsOpen(false);
        }}
        title="As low as 0% APR with Affirm"
      >
        Unlock your exclusive financing offer in seconds with affirm. Rates from
        0-36% APR. Payment options through Affirm are subject to an eligibility
        check and are provided by these lending partners:{' '}
        <a
          href="https://www.affirm.com/lenders"
          target="_blank"
          className="text-white underline"
          rel="noreferrer"
        >
          affirm.com/lenders
        </a>
        <div className="m-0 mt-6">
          <CtaLink
            to="/product-financing/"
            styling="solid-button"
            color="brand"
          >
            Learn More
          </CtaLink>
        </div>
      </StandardModal>
    </>
  );
};

BuyNowPayLaterItem.propTypes = {
  openMobileModal: PropTypes.func,
};

export default BuyNowPayLaterItem;
