import React from 'react';
import PropTypes from 'prop-types';

import IconDesignServices from 'components/icons/IconDesignServices';
import { colorGray } from 'styles/layout/colors';

const DesignServicesIcon = ({ color = colorGray }) => (
  <IconDesignServices width="20" height="26" color={color} />
);

DesignServicesIcon.propTypes = {
  color: PropTypes.string,
};

export default DesignServicesIcon;
