import React from 'react';
import PropTypes from 'prop-types';

const KeySellingPointsBarItem = ({ icon, children, onClick }) => (
  <button
    type="button"
    className="group text-base items-center bg-transparent border-0 flex flex-row flex-1 px-2 py-0 text-left whitespace-nowrap lg:mx-0 lg:my-4 first:max-[1023px]:pl-4 last:max-[1023px]:pr-4 hover:cursor-pointer"
    onClick={onClick}
  >
    <span className="text-gray flex mr-[11px] group-focus:text-brand group-hover:text-brand ml-6">
      {icon}
    </span>
    <span className="leading-[0] text-gray group-focus:text-brand group-hover:text-brand">
      {children}
    </span>
  </button>
);

KeySellingPointsBarItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default KeySellingPointsBarItem;
